import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Radio from "../../../../../components/radio"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import Grid from "../../../../../components/grid"
import Paragraph from "../../../../../components/paragraph"

const RadioGroup = ({ children }) => {
  return (
    <Box
      sx={{
        p: 3,
        bg: "muted",
        height: "100%",
        borderRadius: "lg",
      }}
    >
      <Stack space={3}>{children}</Stack>
    </Box>
  )
}

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "01-zwei-haelften",
          taskId: "lesen-und-einpraegen",
        })
        navigate(
          "/kurse/haelfte-des-lebens/01-zwei-haelften/lesen-und-einpraegen/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Lesen & Einprägen" />
      <Stack>
        <Heading as="h2" level={2}>
          Lesen & Einprägen
        </Heading>
        <Paragraph>
          Hast du dir alles gut eingeprägt? Dann kannst du dein Gedächtnis nun
          überprüfen. Welche Formulierung findet sich tatsächlich im Gedicht?
        </Paragraph>
        <Note variant="task">
          Markiere die Formulierungen, die im Gedicht vorkommen, indem du sie
          anklickst. Klicke erneut darauf, wenn du die Markierung wieder
          entfernen möchtest.
        </Note>
        <Grid columns={[1, 2, 3]} space={6}>
          <RadioGroup>
            <Radio name="q-01" value="Mit reifen Birnen">
              Mit reifen Birnen
            </Radio>
            <Radio name="q-01" value="Mit gelben Birnen">
              Mit gelben Birnen
            </Radio>
            <Radio name="q-01" value="Mit kräftigen Birnen">
              Mit kräftigen Birnen
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-02" value="heilignüchtern">
              heilignüchtern
            </Radio>
            <Radio name="q-02" value="heiligschüchtern">
              heiligschüchtern
            </Radio>
            <Radio name="q-02" value="heiliglüstern">
              heiliglüstern
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-03" value="Ihr edlen Schwäne">
              Ihr edlen Schwäne
            </Radio>
            <Radio name="q-03" value="Ihr holden Schwäne">
              Ihr holden Schwäne
            </Radio>
            <Radio name="q-03" value="Ihr weißen Schwäne">
              Ihr weißen Schwäne
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-04" value="Mit roten Rosen">
              Mit roten Rosen
            </Radio>
            <Radio name="q-04" value="Mit wilden Rosen">
              Mit wilden Rosen
            </Radio>
            <Radio name="q-04" value="Mit duftenden Rosen">
              Mit duftenden Rosen
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-05" value="Sprachlos und kalt">
              Sprachlos und kalt
            </Radio>
            <Radio name="q-05" value="Dunkel und kalt">
              Dunkel und kalt
            </Radio>
            <Radio name="q-05" value="Wortlos und kalt">
              Wortlos und kalt
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-06" value="trunken von Küssen">
              trunken von Küssen
            </Radio>
            <Radio name="q-06" value="selig von Küssen">
              selig von Küssen
            </Radio>
            <Radio name="q-06" value="versunken in Küssen">
              versunken in Küssen
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-07" value="See">
              See
            </Radio>
            <Radio name="q-07" value="Teich">
              Teich
            </Radio>
            <Radio name="q-07" value="Fluss">
              Fluss
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-08" value="Schatten der Erde">
              Schatten der Erde
            </Radio>
            <Radio name="q-08" value="Blumen der Erde">
              Blumen der Erde
            </Radio>
            <Radio name="q-08" value="Samen der Erde">
              Samen der Erde
            </Radio>
          </RadioGroup>
          <RadioGroup>
            <Radio name="q-09" value="Winter">
              Winter
            </Radio>
            <Radio name="q-09" value="Herbst">
              Herbst
            </Radio>
            <Radio name="q-09" value="Frühjahr">
              Frühjahr
            </Radio>
          </RadioGroup>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
